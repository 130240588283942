import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Markdown from 'react-markdown';

const Privacy = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {page_slug: {eq: "privacy_policy"}}){
			    edges{
					node{
						id
						page_title
						page_slug
						page_content
					}
			    }
			}
		}
	`)
	let policy = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		policy.push(node)
	})
	policy = policy[0]
	return (
		<div id="blog-single">
		    <div className="container">
		        <div className="row">
		            <div className="col-lg-12 col-md-12 col-xs-12">
		                <div className="blog-post">
		                    <div className="post-content">
                                <h3>{policy.page_title}</h3>
                                <Markdown source={policy.page_content} escapeHtml={true}/>
                            </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
	)
}

export default Privacy