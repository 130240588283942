import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import Privacy from '../components/privacy-policy'

const PrivacyPolicy = () => (
<Layout>
    <SEO title="Privacy Policy" />
    <SubHeader title='Privacy Policy'/>
    <Privacy/>
</Layout>
)

export default PrivacyPolicy